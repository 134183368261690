var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: "LBL0002109" },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editInfo && !_vm.disabled,
                        expression: "editInfo && !disabled",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isSave,
                      url: _vm.saveUrl,
                      param: _vm.preStartupCheck,
                      mappingType: _vm.mappingType,
                      label: "LBLSAVE",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.savePreStartupCheck,
                      btnCallback: _vm.saveCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          label: "LBL0002103",
                          name: "checkName",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkName,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "checkName", $$v)
                          },
                          expression: "preStartupCheck.checkName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-2" },
                    [
                      _c("c-select", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          codeGroupCd: "PSR_EQUIPMENT_STATUS_CD",
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "psrEquipmentStatusCd",
                          label: "LBL0002100",
                        },
                        model: {
                          value: _vm.preStartupCheck.psrEquipmentStatusCd,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupCheck,
                              "psrEquipmentStatusCd",
                              $$v
                            )
                          },
                          expression: "preStartupCheck.psrEquipmentStatusCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-2" },
                    [
                      _c("c-select", {
                        attrs: {
                          required: true,
                          codeGroupCd: "PSR_CHECK_PERIOD_CD",
                          disabled: _vm.disabled,
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "psrCheckPeriodCd",
                          label: "점검시기",
                        },
                        model: {
                          value: _vm.preStartupCheck.psrCheckPeriodCd,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupCheck,
                              "psrCheckPeriodCd",
                              $$v
                            )
                          },
                          expression: "preStartupCheck.psrCheckPeriodCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-moc", {
                        attrs: {
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          isSubmit: _vm.saveCallData,
                          document: _vm.preStartupCheck,
                          documentId: _vm.param.sopPrestartupCheckId,
                          documentTitle: "checkName",
                          mocRelatedTaskCd: "RT00000015",
                          label: "LBLMOCNO",
                          name: "sopMocId",
                        },
                        on: {
                          "update:document": function ($event) {
                            _vm.preStartupCheck = $event
                          },
                        },
                        model: {
                          value: _vm.preStartupCheck.sopMocId,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "sopMocId", $$v)
                          },
                          expression: "preStartupCheck.sopMocId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-2" },
                    [
                      _c("c-field", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          plantCd: _vm.preStartupCheck.plantCd,
                          type: "user",
                          label: "LBL0002113",
                          name: "checkCancarrierId",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkCancarrierId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupCheck,
                              "checkCancarrierId",
                              $$v
                            )
                          },
                          expression: "preStartupCheck.checkCancarrierId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-2" },
                    [
                      _c("c-dept", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          type: "edit",
                          label: "LBL0002101",
                          name: "checkDeptCd",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkDeptCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "checkDeptCd", $$v)
                          },
                          expression: "preStartupCheck.checkDeptCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-2" },
                    [
                      _c("c-plant", {
                        attrs: {
                          required: true,
                          disabled: _vm.disabled,
                          editable: _vm.editInfo,
                          type: "edit",
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.preStartupCheck.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "plantCd", $$v)
                          },
                          expression: "preStartupCheck.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editInfo,
                    disabled: _vm.disabled,
                    rows: 5,
                    label: "LBL0002114",
                    name: "checkPurpose",
                  },
                  model: {
                    value: _vm.preStartupCheck.checkPurpose,
                    callback: function ($$v) {
                      _vm.$set(_vm.preStartupCheck, "checkPurpose", $$v)
                    },
                    expression: "preStartupCheck.checkPurpose",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editInfo,
                    disabled: _vm.disabled,
                    rows: 5,
                    label: "LBL0002115",
                    name: "checkResultTotal",
                  },
                  model: {
                    value: _vm.preStartupCheck.checkResultTotal,
                    callback: function ($$v) {
                      _vm.$set(_vm.preStartupCheck, "checkResultTotal", $$v)
                    },
                    expression: "preStartupCheck.checkResultTotal",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }