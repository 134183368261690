<template>
  <q-form ref="editForm">
    <!-- 가동전점검 상세 -->
    <c-card title="LBL0002109" class="cardClassDetailForm no-margin">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 저장 -->
          <c-btn 
            v-show="editInfo && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="preStartupCheck"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="savePreStartupCheck"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <!-- 점검명 -->
              <c-text
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                label="LBL0002103"
                name="checkName"
                v-model="preStartupCheck.checkName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
              <!-- 설비상태 -->
              <c-select
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                codeGroupCd="PSR_EQUIPMENT_STATUS_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="psrEquipmentStatusCd"
                label="LBL0002100"
                v-model="preStartupCheck.psrEquipmentStatusCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
              <c-select
                :required="true" 
                codeGroupCd="PSR_CHECK_PERIOD_CD"
                :disabled="disabled"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="psrCheckPeriodCd"
                label="점검시기"
                v-model="preStartupCheck.psrCheckPeriodCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <!-- MOC번호 -->
              <c-moc 
                :editable="editInfo"
                :disabled="disabled"
                :isSubmit="saveCallData"
                :document.sync="preStartupCheck"
                :documentId="param.sopPrestartupCheckId"
                documentTitle="checkName"
                mocRelatedTaskCd="RT00000015"
                label="LBLMOCNO"
                name="sopMocId"
                v-model="preStartupCheck.sopMocId"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
              <!-- 점검총책임자 -->
              <c-field 
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                :plantCd="preStartupCheck.plantCd"
                type="user" 
                label="LBL0002113" 
                name="checkCancarrierId" 
                v-model="preStartupCheck.checkCancarrierId" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
              <!-- 점검부서 -->
              <c-dept 
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                type="edit" 
                label="LBL0002101" 
                name="checkDeptCd" 
                v-model="preStartupCheck.checkDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
              <c-plant 
                :required="true" 
                :disabled="disabled"
                :editable="editInfo" 
                type="edit" 
                name="plantCd" 
                v-model="preStartupCheck.plantCd" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
          <!-- 점검목적 -->
          <c-textarea
            :editable="editInfo"
            :disabled="disabled"
            :rows="5"
            label="LBL0002114"
            name="checkPurpose"
            v-model="preStartupCheck.checkPurpose">
          </c-textarea>
        </div>
        <div class="col-6">
          <!-- 점검결과종합 -->
          <c-textarea
            :editable="editInfo"
            :disabled="disabled"
            :rows="5"
            label="LBL0002115"
            name="checkResultTotal"
            v-model="preStartupCheck.checkResultTotal">
          </c-textarea>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'pre-startup-review-detail',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',
        psrCheckStatusCd: '',
        isSearch: false,
      }),
    },
    preStartupCheck: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',  // 가동전점검 일련 번호
        plantCd: null,  // 사업장코드
        checkName: '',  // 점검명
        checkPurpose: '',  // 점검 목적
        psrEquipmentStatusCd: null,  // 설비 상태
        checkDeptCd: null,  // 점검부서
        sopMocId: '',  // MOC 일련번호
        checkCancarrierId: '',  // 점검 책임자
        checkResultTotal: '',  // 점검결과 종합
        psrCheckStatusCd: '',  // 진행 단계
        psrCheckPeriodCd: null,
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        deleteEquipments: [],
      }),
    },
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
    editInfo: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      editable: true,
      detailUrl: '',
      equipListUrl: '',
      saveUrl: '',
      deleteUrl: '',
      checklistPrintUrl: '',
      checklistReportPrintUrl: '',
      deleteEquipUrl: '',
      isSave: false,
      mappingType: 'POST',
      saveCallData: '',
    };
  },
  watch: {
    'research.item'() {
      this.getEquips();
    }
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 300) + 'px';
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.psr.check.insert.url
      this.deleteUrl = transactionConfig.sop.psr.check.delete.url
      // code setting
      // list setting
    },
    
    savePreStartupCheck() {
      if (this.param.sopPrestartupCheckId) {
        this.saveUrl = transactionConfig.sop.psr.check.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.psr.check.insert.url
        this.mappingType = 'POST';
        this.preStartupEquipment.sopPrestartupCheckId = this.param.sopPrestartupCheckId
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.preStartupCheck.regUserId = this.$store.getters.user.userId
              this.preStartupCheck.chgUserId = this.$store.getters.user.userId

              if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
                this.preStartupCheck.equipments = this.$_.filter(this.preStartupCheck.equipments, null)
              }
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.sopPrestartupCheckId = result.data
      this.saveCallData = uid();
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getPreStartupCheck();
    },
  }
};
</script>
<style lang="sass">
.preStartup-tab-no-padding
  .q-tab-panel
    padding: 3px !important
    .q-table__container
      margin: 0px !important
</style>